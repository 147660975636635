.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 90px;
        width: 100%;
        /* background-color: #5850f5; */
        background-color: #1F2A40;
        color: rgb(255, 255, 255);
}

.logo {
    text-align: center;
    width: 20%;
}



.logo h3 {
    margin-top: -30px;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    width: 700px;
    margin-right: 60px;
}

.nav-links a {
    text-decoration: none;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.underline{
    height: 3px;
	background-color: transparent;
	width: 0%;
	transition: width 0.2s, background-color 0.5s;
	margin: 0 auto;
}
.nav-links a:hover {
    cursor: pointer;
}

span{
    color: #5850f5;
}
button{
    height: 40px;
}

main{
    position: absolute;
}
.nav-links a.active .underline{
    /* text-decoration: underline; */
    width: 100%;
	background-color: #ffffff;
}
.nav-links a:hover .underline {
    width: 100%;
	background-color: #ffffff;
}
.sidenav{
     height: 100%;
            width: 0px;
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            background-color: rgb(255, 255, 255);
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px;
}
.sidenav.active{
    width: 250px;
}
.nav-opener-icon{
    height: 30px;
    width: 30px;
}
.nav-links-ham{
    color: black;
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
}
.nav-links-ham a{
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    font-size: 25px;
    font-weight: 100;
}
.side-blur{
     height: 100%;
            width: 100%;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background: rgb(111,111,111,0.5);
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px;
            display: none;
}
.side-blur.active{
    display: block;
     backdrop-filter: blur(5px);
}
@media only screen and (max-width: 600px){
    .logo{
        margin-left: 20px;
    }
    .floater-nav{
        width: 50px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    /* background-color: #5850f5; */
    color: rgb(0, 0, 0);
    border-radius: 50px;
    text-align: center;
    }
    .nav-opener{
        justify-content: flex-end;
        width: 120px;
        margin-right: -20px;
        right: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    }
    /* .nav-links-ham a.active .underline{
        width: 100%;
	background-color: #5850f5;
}
.nav-links-ham a:hover .underline {
    width: 100%;
	background-color: #5850f5;
} */
    .nav-links-ham a.active{
	color: #5850f5;
}
.nav-links-ham a:hover {
	color: #5850f5;
}
}