.skills{
    height: 60vh;
}
.skills-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.skills-overlap{
    height: 100px;
    width: 100%;
    background-color: #1F2A40;
}
.skills-floater{
    position: absolute;
    width: 1200px;
    height: 50vh;
    background-color: rgb(255, 255, 255);
    /* box-shadow: 0px 0px 18px 6px rgba(0,0,0,0.5); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}


.icon{
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
.skills-upper{
    width: 100%;
    height: 20%;
}
.skills-lower{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
}
.skills-lower > *{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.skill-title{
    font-size: 10px;
    color: #5850f5;
    font-weight: 100;
}
.skill-list{
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.skill-list>*{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    background-color:#f7f7ff;
    color: #697477;
}
@media only screen and (max-width: 600px){
    .skills-floater{
    position: absolute;
    width: 375px;
    height: 50vh;
    background-color: rgb(255, 255, 255);
    /* box-shadow: 0px 0px 18px 6px rgba(0,0,0,0.5); */
    border-radius: 10px;
  
}
.skill-list >*{
    font-size: 10px;
}
}
.hide1{
    opacity: 0;
    bottom:  0;
    margin-right: 100px;
}

.show1 {
    opacity: 1;
    margin-right: 0;
}