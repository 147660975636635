.showcase {
    background-color: #ffffff;
    height: 300vh;
    display: flex;
   color: rgb(0, 0, 0);
   align-items: center;
   flex-direction: column;
    
}

.projects{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.project-container{
    width: 1000px;
    height: 400px;
    display:  flex;
background: #ffffff;
   
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
}

.project-info{
    width: 50%;
    height: 100%;
    color: #000000;
}

.project-sc {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.window{
    width: 90%;
    height: 80%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
}
.window-nav{
    width: 100%;
    height: 10%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: saturate(100%) blur(20px);
}
.circle{
    display: flex;
    width: 15%;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}
.window-sc{
    width: 100%;
    height: 90%;
    bottom: 0;
}

.project-header{
height: 20%;
display: flex;
}
.project-title{
    width: 50%;
    text-align: start;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.project-links{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-desc {
    height: 60%;
    border-right: 1px solid #5850f5;
}
.project-tech {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #5850f5;
}
.project-tech p{
    font-style: italic;
}

.gitlink{
    color: #5850f5;
    opacity: .2;
    height: 50px;
    width: 50px;
    padding-right: 40px;
    transition: .5s;
}

.weblink{
    opacity: .2;
    color: #5850f5;
    height: 50px;
        width: 50px;
        transition: .5s;
}

.gitlink:hover{
    color: #5850f5;
    opacity: 1;
}

.weblink:hover{
    color: #5850f5;
    opacity: 1;
}


@media only screen and (max-width: 600px){
  .project-container{
    width: 375px;
    height: 500px;
    flex-direction: column;
    
}
.project-info{
    width: 100%;
    height: 50%;
    color: #000000;
    padding-top: 20px;
}
.project-sc {
    width: 100%;
    height: 50%;
}
.showcase{
    height: 350vh;
}
.project-desc {
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}
.case-header{
    padding-top: 20px
}
}