.contact{
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #1F2A40;
    color: #1F2A40;
}
.contact div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
}

