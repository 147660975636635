
.about {
    height: 70vh;
    color: #ffffff;
    background-color: #1F2A40;
   
   
    
}

.about-container {
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.about-container >*{
    /* border: 1px solid; */
    width: 50%;
    height: 70%;
}
.about-left{

    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: start;
    border-right: 5px solid #ffffff;
}
.about-text{
    /* border: 1px solid; */
    width: 700px;
    height: 500px;
    padding-left: 50px;
    padding-right: 20px;
}
.about-text p {
    font-size: 25px;
}
.about-text h3 span{
    color: white;
}


.hide{
    opacity: 0;
}

.show {
    opacity: 1;   
}
.about-right
.memoji{
    height: 550px;
    width: 550px;
}
@media only screen and (max-width: 600px){
    .about-container {
        flex-direction: column;
    }
    .about-left{
    display: flex;
    align-items: center;
    text-align: start;
    height: 100%;
    width: 100%;
    border: none;
}
.about-right{
    display: none;

  
}
.about-text{
    margin-top: -50px;
    
}
.about-text p{
    font-size: 18px;
}
}