@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.App {
  text-align: center;
font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.floater{
  position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    /* background-color: #5850f5; */
    color: rgb(0, 0, 0);
    border-radius: 50px;
    text-align: center;
}

.floater2{
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 40px;
  /* background-color: #22f1bf; */
  color: rgb(0, 0, 0);
  border-radius: 50px;
  text-align: center;
}
.change:hover{
  color: #5850f5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px){
  .change{
    display: none;
  }
}