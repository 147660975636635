
.home{
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-family: 'PT Sans', sans-serif; */
    /* box-shadow: 0px -4px 3px rgba(0, 0, 0, 0); */
}

.home-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    /* border: 1px solid; */
    width: 100%;
    height: 80%;
    /* font-family: 'DM Sans', sans-serif; */

}

.welcome{
    width: 70%;
    /* border: 1px solid; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}
.title{
    font-size: 70px;
    opacity: 0;
        animation-name: fadein;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: .5s;
        color: #000000;
        /* border: 1px solid red; */
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
}
.title-line-1{
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
/* margin-right: 20px; */
}
.title-line-2{
    /* font-weight: 900; */
    font-size: 100px;
}
.desc{
    opacity: 0;
        animation-name: fadein;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: .6s;
        color: #000000;
        /* border: 1px solid; */
        font-weight: 100;
}
.name-tag{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 100px;
}
.profile{
    /* border: 1px solid; */
    width: 50%;
    height: 100%;
}
.img-container{
    /* border: 1px solid; */
    /* width: 100%; */
    
    height: 100%;
    display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
            animation-name: fadein;
            animation-duration: 500ms;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: .8s;
}

.img-container img {
    /* border: 1px solid; */
    height: auto;
        width: 70%;
        max-width: 520px;
        max-height: 650px;
    border-radius: 20px;
}
.home-links{
    /* border: 1px solid red; */
    width: 600px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
        animation-name: fadein;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: .7s;
        
}
.home-link-1{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 60px;
    width: 150px;
    /* background-color: #5850f5; */
    font-size: 20px;
   font-weight: 100;
    color: #5850f5;
}
.home-link-2{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 60px;
    width: 150px;
    /* background-color: #ffffff; */
    font-size: 20px;
    font-weight: 100;
    color: #5850f5;
    margin-left: 20px;
}

.home-link-2 a{
    text-decoration: none;
    color: inherit;
    
}
.home-link-1:hover{
    color: rgb(0, 0, 0);
        transition-duration: .2s;
        cursor: pointer;
    
}
.home-link-2:hover{
    color: rgb(0, 0, 0);
        transition-duration: .2s;
        cursor: pointer;
    
}
.position-title{
    font-size: 55px;
    margin-bottom: 50px;
    font-weight: 300;
    color: #1F2A40;
}

.title-line-1 span{
    color: #000000;
    font-weight: 900;
    font-size: 30px;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px){
    .title{
        font-size: 30px;
        
    }
    .title-line-2{
    font-size: 35px;
}
   .title-line-1{
    margin-left: 10px;
    color: red;
}
.position-title{
    font-size: 30px;
}
.home-link-1{
    height: 40px;
    /* width: 100px; */
    font-size: 20px;
    font-weight: 100;
}
.home-link-2{
    font-size: 20px;
    font-weight: 100;
}
.home{
    height: 70vh;
}
.home-links{
    width: 100%;
}
.welcome{
    margin-top: 40px;
}
}